import { render, staticRenderFns } from "./Partners.vue?vue&type=template&id=aa78cbde&scoped=true"
import script from "./Partners.vue?vue&type=script&lang=js"
export * from "./Partners.vue?vue&type=script&lang=js"
import style0 from "./Partners.vue?vue&type=style&index=0&id=aa78cbde&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa78cbde",
  null
  
)

export default component.exports