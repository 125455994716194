<template>
  <div>
    <div class="partner">
      <div class="inner">
        <div class="title">Partner With Us</div>
        <div class="content margin-top-15">
          <div>We believe a diverse payments ecosystem helps businesses of all sizes and types. This means fostering an environment that offers connectivity between Moozumi’s payment service partners and our global network of merchants and platform customers.</div>
          <div class="margin-top-20">Becoming a Moozumi partner enables access to the world of Payment Orchestration and brings increased value to more merchants, merchant aggregators, and marketplaces around the world. It drives faster customer acquisition, accelerated time to market, and increased customer retention. That means more revenue and happier customers for everyone.</div>
        </div>
      </div>
    </div>
    <div class="partnership">
      <div class="inner">
        <div class="title">
          <div>Preferred Partnership Program</div>
          <div class="content margin-top-15">
            <div>By partnering with Moozumi, you’ll gain functionality that prospects want — which allows you to focus on the key differentiators that separate you from your competitors.</div>
            <div class="margin-top-20">The Preferred Partnership Program is a strategic relationship level. Moozumi and Preferred Partners engage closely to build better, more holistic payments solutions.</div>
          </div>
          <img class="margin-top-25" src="../../assets/images/connections_partners/p1.png" />
        </div>
      </div>
    </div>
    <div class="moozumi">
      <div class="inner">
        <div class="title">
          <div>How Moozumi Supports You</div>
        </div>
        <div class="content">
          <template v-for="(item, index) in moozumiList">
            <div class="content-item" :key="index">
              <template v-for="(subitem, subindex) in item">
                <div class="content-sub-item" :key="subindex">
                  <div class="text">{{subitem}}</div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <MoozumiEmail></MoozumiEmail>
  </div>
</template>
<script>
import MoozumiEmail from '../../components/MoozumiEmail.vue'
export default {
  components: { MoozumiEmail },
  data () {
    return {
      moozumiList: [
        [
          'Land more merchants by leveraging a non-competitive orchestration platform',
          'Faster path to revenue with new merchants via a single connection'
        ],
        [
          'Gain a foothold with merchants by supporting payment experimentation',
          'Access to marketing and sales resources'
        ],
        [
          'Retain churning customers by avoiding an "all or nothing" approach to acquiring'
        ]
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';
.partner {
  padding: 120px 0;
  background: url(../../assets/images/connections_partners/bg1.png);
  background-size: cover;
  .inner {
    width: 870px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: Helvetica-Bold;
      font-size: 52px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      line-height: 68px;
    }
    .content {
      font-family: HelveticaNeue;
      font-size: 18px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      line-height: 36px;
    }
  }
}
.partnership {
  padding: 120px 0 110px;
  background: $white;
  .inner {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $content-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content {
      font-family: HelveticaNeue;
      font-size: 20px;
      color: $primary-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 36px;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
.moozumi {
  padding: 120px 0;
  background: url(../../assets/images/connections_partners/bg2.png);
  background-size: cover;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $content-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content {
      background: $white;
      box-shadow: 0 0 77px 0 rgba(0,0,0,0.06);
      border-radius: 18px;
      padding: 19px 33px 19px;
      margin-top: 50px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .content-item {
        position: relative;
        width: 320px;
        max-width: 100%;
        border-left: 1px dashed $border-color;
        font-family: HelveticaNeue;
        font-size: 18px;
        color: $primary-color;
        letter-spacing: 0;
        text-align: justify;
        line-height: 36px;
        padding-left: 19px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .content-sub-item {
          &:before {
            content: '';
            width: 4px;
            height: 22px;
            position: absolute;
            background: $primary-color;
            left: -2px;
          }
          .text {
            position: relative;
            top: -10px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .partner, .partnership, .moozumi {
    .inner {
      padding: 0 15px;
    }
  }
}
</style>
